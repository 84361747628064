import { call } from '@advanza/api'
import { changeEntity } from '@advanza/redux_entity'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import { getCompanyBaseUrlOffice } from 'misc/companyBaseUrlOffice'
import { useState } from 'react'

export function usePublicReviews(providerId: number) {
    const dispatch = useAppDispatch()
    const providers = useAppSelector((state) => state.providers.entities.providers)
    const provider = providers && providers[providerId]
    const baseUrl = getCompanyBaseUrlOffice(provider?.country_code)
    const url = provider?.public_reviews && baseUrl + '/r/' + provider.public_reviews

    const hasPublicReviews = provider && provider.public_reviews
    const [isGenerating, setIsGenerating] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)

    const onGenerate = () => {
        setIsGenerating(true)
        call(`providers/generate-public-reviews-key/public_reviews/${providerId}`, {
            method: 'post',
        }).then((response) => {
            dispatch(
                changeEntity({
                    store: 'providers',
                    name: 'providers',
                    key: providerId,
                    diff: {
                        public_reviews: response.key,
                    },
                })
            )
            setIsGenerating(false)
            setIsGenerated(true)
        })
    }

    return {
        url,
        hasPublicReviews,
        onGenerate,
        isGenerating,
        isGenerated,
    }
}
