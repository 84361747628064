import { Select, Switch } from '@advanza/advanza_generic'
import { call } from '@advanza/api'
import { Col, Row } from '@advanza/grid'
import Modal from '@advanza/modal'
import { Button, Divider } from '@advanza/ui'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const BatchMailButton = ({ selection, filterId }) => {
    const dispatch = useDispatch()
    const [batchMailing, setBatchMailing] = useState(false)
    const [force, setForce] = useState(false)
    const ids = useSelector(
        (state) => state.invoices.filters[filterId] && state.invoices.filters[filterId].selection
    )
    const active = selection.length > 0

    async function onClickSend() {
        setBatchMailing(false)

        for (const id of ids) {
            dispatch({ type: 'SET_LOADING_INVOICE', id, isLoading: true })
            await call(
                `office/invoices/send-batch-mail/${id}/${batchMailing}/${force ? '1' : ''}`,
                { method: 'post' }
            )
                .then(
                    () => dispatch({ type: 'SET_INVOICE_ACTION_STATUS', id, status: 'success' }),
                    () => {}
                )
                .finally(() => dispatch({ type: 'SET_LOADING_INVOICE', id, isLoading: false }))
        }

        dispatch({ type: 'INVALIDATE_INVOICES' })
    }

    return (
        <div>
            {active && (
                <Select
                    options={[
                        { value: 'InvoiceMail', title: 'Send invoice mail' },
                        {
                            value: 'InvoiceReminderMail',
                            title: (
                                <span>
                                    Send <b>First</b> invoice reminder mail
                                </span>
                            ),
                        },
                        {
                            value: 'InvoiceReminderMail_secondReminder',
                            title: (
                                <span>
                                    Send <b>Second</b> invoice reminder mail
                                </span>
                            ),
                        },
                        {
                            value: 'InvoiceReminderMail_finalReminder',
                            title: (
                                <span>
                                    Send <b>Final</b> invoice reminder mail
                                </span>
                            ),
                        },
                    ]}
                    className="select-min select-wide select-stealth"
                    placeholder="send email"
                    onChange={(e) => setBatchMailing(e.target.value)}
                />
            )}
            <Modal open={batchMailing} close={() => setBatchMailing(false)}>
                <Row>
                    <Col>
                        {batchMailing === 'InvoiceMail' ? (
                            <h3>Send invoice emails</h3>
                        ) : (
                            <h3>Send invoice reminder emails ({batchMailing})</h3>
                        )}
                    </Col>
                </Row>
                <Divider s />
                <Row>
                    <Col x>Selected invoice #:</Col>
                    <Col x>{selection.toString()}</Col>
                </Row>
                <Divider m />
                <Row>
                    <Col x>
                        <Switch checked={force} onChange={() => setForce(!force)} />
                    </Col>
                    <Col x>Force send.</Col>
                </Row>
                <Divider l />
                <Row middle="xs">
                    <Col x>
                        <Button onClick={() => onClickSend()}>Send</Button>
                    </Col>
                    <Col x>
                        <button onClick={() => setBatchMailing(false)}>Cancel</button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default BatchMailButton
