import { Dispatch, SetStateAction } from 'react'

type StateMap = Record<string | number, any>

const recurse = (state: StateMap = {}, keys: (string | number)[], value: any): StateMap => ({
    ...state,
    [keys[0]]: keys.length === 1 ? value : recurse(state[keys[0]], keys.slice(1), value),
})

export const createMergeSetter =
    <ValueType>(setter: Dispatch<SetStateAction<any>>) =>
    (keys: (string | number)[], value: ValueType) =>
        setter((state: StateMap) => recurse(state, keys, value))

export const objectKeys = <T extends {}>(obj: T) =>
    Object.keys(obj) as `${keyof T & (string | number)}`[] // show that the keys are always returned as strings

export const objectKeysAsInts = (obj: {}) => Object.keys(obj).map((key) => parseInt(key, 10))

export const pick = <T extends {}, K extends keyof T>(obj: T, keys: K[]) => {
    const newObj = {} as Pick<T, K>
    keys.forEach((key) => {
        newObj[key] = obj[key]
    })
    return newObj
}
