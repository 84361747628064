import { call } from '@advanza/api'
import { changeEntity } from 'actions/entities'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export function useLicencesLabel(licenceId, officeLabelId) {
    const dispatch = useDispatch()
    const licences = useSelector((state) => state.licences.entities.licences)
    const [isAssigning, setIsAssigning] = useState(false)
    const [assigned, setAssigned] = useState(-1)
    const [isActivating, setIsActivating] = useState(false)
    const [activated, setActivated] = useState(-1)

    const addLabel = () => {
        const tempId = Math.random().toString(36).substr(2, 5)
        dispatch(
            changeEntity({
                store: 'labels',
                name: 'labels',
                key: tempId,
                diff: {
                    licence_id: licenceId,
                    category: 'profile',
                    subcategory: 'qualification',
                    type: 'qualification',
                    color: '#ffedd7',
                    text_color: '#ff7e00',
                    description: licences[licenceId].description,
                    icon: 'star',
                },
            })
        )
        return Promise.resolve(tempId)
    }

    const assignLabel = () => {
        setIsAssigning(true)
        return call(`office/licences-labels/assign-label/${licenceId}/${officeLabelId}/0/10`, {
            method: 'post',
        }).then(({ assigned }) => {
            setIsAssigning(false)
            setAssigned(assigned)
        })
    }

    const activateLabel = () => {
        setIsActivating(true)
        return call(`office/licences-labels/activate-label/${licenceId}/${officeLabelId}`, {
            method: 'post',
        }).then(({ activated }) => {
            setIsActivating(false)
            setActivated(activated)
        })
    }

    return {
        addLabel,
        isAssigning,
        assignLabel,
        assigned,
        isActivating,
        activateLabel,
        activated,
    }
}

export function saveLabel(officeLabelId) {
    return function (dispatch, getState) {
        const officeLabel = getState().labels.entities.labels[officeLabelId]
        const isNew = typeof officeLabelId === 'string'
        return call(`office/licences-labels/save-label/${isNew ? 0 : officeLabelId}`, {
            json: {
                officeLabel,
                licenceId: officeLabel.licence_id,
                userId: window._user.id,
            },
        }).then((response) => {
            if (isNew) {
                dispatch({ type: 'INVALIDATE_LABELS' })
                dispatch(
                    changeEntity({
                        store: 'licences',
                        name: 'licences',
                        key: officeLabel.licence_id,
                        diff: {
                            licences_label: response.licencesLabel,
                        },
                    })
                )
            }
        })
    }
}
