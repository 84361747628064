import { Col, Row } from '@advanza/grid'
import { SelectSimple } from '@advanza/input'
import Modal from '@advanza/modal'
import { OptionStatus } from '@advanza/types/Option'
import { QuestionStatus } from '@advanza/types/Question'
import { Divider, Icon, PreIcon } from '@advanza/ui'
import { deleteQuestion, saveQuestion } from 'actions/services'
import Card from 'components/Card'
import CheckButton from 'components/CheckButton'
import BagUse from 'components/services/BagUse'
import EntityComponent from 'components/services/EntityComponent'
import Option from 'components/services/Option'
import SafeButton from 'components/ui/SafeButton'
import EntityComponentContainer from 'containers/EntityComponentContainer'
import { Fragment } from 'react'
import style from './questionEntity.module.css'

class QuestionEntity extends EntityComponent {
    constructor(props) {
        super(props)

        this.isTouched = this.isTouched.bind(this)
        this.getAllServiceOptionsForQuestionRules =
            this.getAllServiceOptionsForQuestionRules.bind(this)
        this.highlightQuestionsSubjectToRules = this.highlightQuestionsSubjectToRules.bind(this)
        this.checkQuestionRulesAreOrdered = this.checkQuestionRulesAreOrdered.bind(this)
        this.changeOrder = this.changeOrder.bind(this)
        this.addOption = this.addOption.bind(this)
        this.toggleShowHiddenOptions = this.toggleShowHiddenOptions.bind(this)
        this.Options = []
        this.state = {
            showHiddenOptions: false,
            error: false,
        }
    }

    getAllServiceOptionsForQuestionRules() {
        const { serviceEntities, service, index } = this.props

        const selectOptions = []
        const questionsOrder = service.questions
        service.questions.forEach((id) => {
            if (
                questionsOrder.indexOf(id) < index &&
                [OptionStatus.ACTIVE, OptionStatus.NEW].includes(
                    serviceEntities.questions[id].status
                )
            ) {
                const question = serviceEntities.questions[id]
                serviceEntities.questions[id].options.forEach((id) => {
                    const option = serviceEntities.options[id]
                    const isNew =
                        question.status === QuestionStatus.NEW || option.status === OptionStatus.NEW
                    selectOptions.push({
                        title: `${isNew ? '[NEW] ' : ''}${question.question} - ${option.value}`,
                        value: id,
                        queryField: `${question.question} ${option.value}`,
                    })
                })
            }
        })

        return selectOptions
    }

    componentDidMount() {
        const { entity } = this.props

        if (entity._openToggle) {
            this.ref && this.ref.scrollIntoView()
        }

        if (entity.status !== QuestionStatus.ACTIVE) {
            this.toggleShowHiddenOptions(true)
        }
    }

    checkQuestionRulesAreOrdered() {
        const { service, serviceEntities } = this.props
        const order = service.questions

        for (let i = 0; i < order.length; i++) {
            const id = order[i]
            const thisQuestionIndex = order.indexOf(id)
            const question = serviceEntities.questions[id]
            for (const key in question.question_rules) {
                const option = serviceEntities.options[question.question_rules[key]]
                const questionIndex = order.indexOf(option.question_id)
                if (questionIndex > thisQuestionIndex) {
                    console.log({
                        thisQuestionIndex,
                        questionIndex,
                        key,
                        a: option.question_id,
                        qr: question.question_rules[key],
                    })
                    return false
                }
            }
        }

        return true
    }

    validate() {
        const questionIsValid = super.validate()
        let optionsAreValid = true
        this.Options.filter(Boolean).forEach((ref) => {
            const Option = ref.getWrappedInstance()
            if (Option && !Option.validate()) {
                optionsAreValid = false
            }
        })

        const orderIsValid = this.checkQuestionRulesAreOrdered()
        return questionIsValid && optionsAreValid && orderIsValid
    }

    editFields() {
        const { entity: question, typeMap, serviceEntities, service } = this.props
        return {
            question: {
                type: 'text',
                textArea: question.type === 's',
                autoFocus:
                    question._openToggle && !(question.question && question.question.length > 2),
                msg: "Question e.g. 'What services do you require?'",
                validator: (val) => val && val.length > 1 && val.length < 512,
            },
            question_name_pro: {
                type: 'text',
                msg: "Question PRO e.g. 'What services do you provide?'",
                validator: (val) =>
                    !question.filterable || question.type === 's'
                        ? true
                        : val && val.length > 1 && val.length < 155,
                isHidden: !question.filterable || question.type === 's',
            },
            question_name_filter: {
                type: 'text',
                msg: "Short e.g. 'Services'",
                isHidden: question.type === 's',
                validator: (val) =>
                    question.type === 's' ? true : val && val.length > 1 && val.length < 155,
            },
            linked_to: {
                type: 'select',
                placeholder: 'Linked to',
                options: [
                    { name: 1, value: 1 },
                    { name: 2, value: 2 },
                    { name: 3, value: 3 },
                ],
            },
            description: {
                type: 'text',
                msg: '*optional* Description (the subline of the question)',
                validator: (val) => (val ? val.length > 1 && val.length < 100 : true),
            },
            filterable: {
                type: 'boolean',
                msg: 'Filterable',
                isHidden: ['1', '2'].indexOf(question.type) === -1 || question.after_conversion,
            },
            is_availability: {
                type: 'boolean',
                msg: (
                    <PreIcon icon="event" fontSize={17}>
                        Availability
                    </PreIcon>
                ),
                isHidden: question.after_conversion,
            },
            is_privacy_sensitive: {
                type: 'boolean',
                msg: 'Privacy sensitive',
            },
            optional: {
                isHidden: question.filterable || question.type === 'd',
                type: 'boolean',
                msg: 'Optional',
                disabled: question.after_conversion, // not allowed toggle the optional to false if the after conversion is true
            },
            after_conversion: {
                type: 'boolean',
                msg: (
                    <PreIcon icon="text_select_move_forward_word" fontSize={17}>
                        After conversion
                    </PreIcon>
                ),
                isHidden:
                    question.filterable ||
                    ['d', 'm', 'v'].includes(question.type) ||
                    question.is_availability,
                onChange: (e) => {
                    const { value } = e.target
                    if (value) {
                        // after conversion questions are always optional, so set both to true
                        this.onChangeEntity({
                            after_conversion: true,
                            optional: true,
                        })
                    } else {
                        this.onChangeEntity({
                            after_conversion: false,
                        })
                    }
                },
            },
            default_option: {
                type: 'select',
                placeholder: 'default option',
                options: question.options.map((id) => {
                    return {
                        value: id,
                        title: serviceEntities.options[id].value,
                    }
                }),
            },
            placeholder: {
                isHidden:
                    ['2', '1', '5', '0', '6', '7', '9', 'd', 'o'].indexOf(question.type) === -1,
                type: 'text',
                msg: 'placeholder',
            },
            type: {
                type: 'select',
                options: Object.keys(typeMap)
                    .map((key) => ({
                        type: key,
                        ...typeMap[key],
                    }))
                    .filter((item) => {
                        if (item.type === question.type) {
                            // the type that is already selected should always be visible
                            return true
                        }

                        if (item.deprecated) {
                            // hide question types that are deprecated
                            return false
                        }

                        if (item.required && !service[item.required]) {
                            // hide question types that are not enabled for the service
                            return false
                        }

                        return true
                    })
                    .map((item) => {
                        const invalidType =
                            (item.required && !service[item.required]) || item.deprecated

                        return {
                            title: (
                                <PreIcon
                                    icon={item.icon}
                                    overflowFix
                                    red={invalidType}
                                    className={invalidType ? style.invalidType : ''}>
                                    {item.name}{' '}
                                    {invalidType &&
                                        `[${item.deprecated ? 'DEPRECATED' : 'NOT ENABLED'}]`}
                                </PreIcon>
                            ),
                            value: item.type,
                        }
                    }),
                validator: (value) => (value < 3 ? question.options.length > 0 : true),
                className: style.questionType,
            },
            status: {
                type: 'select',
                placeholder: 'Status',
                options: [
                    { title: <PreIcon name="fiber_new">New</PreIcon>, value: QuestionStatus.NEW },
                    {
                        title: <PreIcon name="visibility">Active</PreIcon>,
                        value: QuestionStatus.ACTIVE,
                    },
                    {
                        title: <PreIcon name="visibility_off">Hidden</PreIcon>,
                        value: QuestionStatus.HIDDEN,
                    },
                ],
                onBeforeChange: (value) =>
                    value !== QuestionStatus.ACTIVE && this.props.toggleShowHiddenQuestions(true),
                className: style.questionStatus,
            },
            question_rules: {
                type: 'select',
                icon: 'shuffle',
                placeholder: 'Only show question when:',
                useSearch: true,
                options: this.getAllServiceOptionsForQuestionRules(),
                multiple: true,
                value: question.question_rules,
                onValueChange: (values) =>
                    this.onChangeEntity({
                        question_rules: values.map((item) => item.value),
                    }),
                validator: this.checkQuestionRulesAreOrdered,
            },
            calculator_value: {
                type: 'number',
                msg: '€ calc. value',
                isHidden: question.type !== '7',
                title: 'If left empty, this question will disable the calculator when a value > 0 is entered',
            },
        }
    }

    isTouched() {
        const { entity: question, serviceEntities } = this.props
        const questionIsTouched = question._isTouched
        const optionsAreTouched =
            question.options.filter((id) => serviceEntities.options[id]._isTouched).length > 0
        const { bag_uses = {} } = serviceEntities
        const bagUse = bag_uses[question.bag_use || `-${question.question_id}`]
        const bagUseIsTouched = bagUse && bagUse._isTouched

        return questionIsTouched || optionsAreTouched || bagUseIsTouched
    }

    changeOrder(upOrDown, e) {
        const { entity: question, changeQuestionOrder, index } = this.props
        changeQuestionOrder(question, upOrDown)

        // change the entity so that the save button will appear. It doesn't mean anything
        this.onChangeEntity({ '|index': index })
    }

    highlightQuestionsSubjectToRules(hover) {
        const { entity: question, setHighlight, serviceEntities } = this.props
        const highlights = {}
        question.question_rules.forEach((optionId) => {
            const option = serviceEntities.options[optionId]
            highlights[option.question_id] = (highlights[option.question_id] || []).concat(
                <span className="label">{option.value}</span>
            )
        })

        if (hover) {
            setHighlight(highlights)
        } else {
            setHighlight({})
        }
    }

    toggleShowHiddenOptions(show = null) {
        this.setState({
            showHiddenOptions: show !== null ? show : this.state.showHiddenOptions !== true,
        })
    }

    addOption() {
        const { entity: question, addOption } = this.props
        addOption(question.question_id)
    }

    save(...params) {
        super.save(params).then(
            (response) => {},
            (response) => {
                this.setState({ error: response })
            }
        )
    }

    render() {
        const { entity: question, serviceEntities, service, addOptionImage } = this.props
        const { showHiddenOptions } = this.state
        const showOptions = question.type < 3

        return (
            <Fragment>
                <Row middle="xs" className={style.sticky}>
                    <Col x>{this.renderInput('status')}</Col>
                    <Col x>{this.renderInput('type')}</Col>
                    {this.isVisible('is_availability') && (
                        <Col x>{this.renderInput('is_availability')}</Col>
                    )}
                    {this.isVisible('filterable') && <Col x>{this.renderInput('filterable')}</Col>}
                    {this.isVisible('optional') && <Col x>{this.renderInput('optional')}</Col>}
                    {this.isVisible('after_conversion') && (
                        <Col x>{this.renderInput('after_conversion')}</Col>
                    )}
                    <Col x>{this.renderInput('is_privacy_sensitive')}</Col>
                    <Col x style={{ marginRight: 0, marginLeft: 'auto' }}>
                        <Row middle="xs">
                            <Col x>
                                <SafeButton
                                    action={this.delete}
                                    cancelText={false}
                                    className="plain c-red"
                                    confirmMessage="Confirm delete"
                                    buttonText={<Icon>delete_forever</Icon>}
                                />
                            </Col>
                            <Col x>
                                <button
                                    onClick={this.save}
                                    className="btn cta"
                                    disabled={!this.isTouched()}>
                                    {question._saving ? 'saving..' : 'Save'}
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row center="xs" className={style.questionRow}>
                    <Col xs={6}>
                        <Card header="Question">
                            <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
                                <Col style={{ maxWidth: 478 }}>
                                    {this.renderInput('question_rules')}
                                </Col>
                                {this.isVisible('calculator_value') && (
                                    <Col x>{this.renderInput('calculator_value')}</Col>
                                )}
                            </Row>
                            <Divider m />
                            <div
                                ref={(el) => {
                                    this.ref = el
                                }}>
                                {' '}
                            </div>
                            {this.renderInput('question')}
                            <Divider m />
                            {question.type === 's' && (
                                <Fragment>
                                    <SelectSimple
                                        value={question.linked_to}
                                        icon={<Icon name="link" />}
                                        placeholder="Linked to"
                                        onChange={(e) =>
                                            this.onChangeEntity({ linked_to: e.target.value })
                                        }
                                        options={[
                                            {
                                                name: '-',
                                                value: null,
                                            },
                                        ].concat(
                                            service.questions
                                                .filter((id) => {
                                                    const question = serviceEntities.questions[id]
                                                    return question.type !== 's'
                                                })
                                                .map((id) => {
                                                    const question = serviceEntities.questions[id]
                                                    return {
                                                        name: question.question,
                                                        value: id,
                                                    }
                                                })
                                        )}
                                    />
                                    <Divider m />
                                </Fragment>
                            )}
                            {this.isVisible('question_name_pro') && (
                                <Fragment>
                                    {this.renderInput('question_name_pro')}
                                    <Divider m />
                                </Fragment>
                            )}

                            {this.isVisible('question_name_filter') && (
                                <Fragment>
                                    {this.renderInput('question_name_filter')}
                                    <Divider m />
                                </Fragment>
                            )}

                            {this.renderInput('description')}
                            <Divider m />

                            {this.isVisible('placeholder') && this.renderInput('placeholder')}

                            {(question.type === 'p' || question.type === 'l') && (
                                <BagUse entityId={question.bag_use || `-${question.question_id}`} />
                            )}
                        </Card>
                    </Col>
                    {showOptions && (
                        <Col style={{ minWidth: 500 }} xs={6} className="grow">
                            <Card header="options">
                                <Row middle="xs">
                                    <Col x>
                                        <CheckButton
                                            onChange={this.toggleShowHiddenOptions.bind(this, null)}
                                            checked={showHiddenOptions}
                                            msg="Show hidden"
                                        />
                                    </Col>
                                    <Col x>
                                        <button onClick={this.addOption}>
                                            <Icon>add</Icon>
                                        </button>
                                    </Col>
                                </Row>
                                <Divider m />
                                {question.options
                                    .sort(
                                        (a, b) =>
                                            serviceEntities.options[a].order_value -
                                            serviceEntities.options[b].order_value
                                    )
                                    .map((id, i) => {
                                        if (
                                            serviceEntities.options[id].status !==
                                                OptionStatus.ACTIVE &&
                                            !showHiddenOptions
                                        ) {
                                            return null
                                        }
                                        return (
                                            <Fragment key={id}>
                                                <Option
                                                    toggleShowHidden={this.toggleShowHiddenOptions}
                                                    ref={(el) => this.Options.push(el)}
                                                    entityId={id}
                                                    index={i + 1}
                                                    service={service}
                                                    addOptionImage={addOptionImage}
                                                    option={serviceEntities.options[id]}
                                                    questionType={question.type}
                                                />
                                                <Divider name="border" l />
                                            </Fragment>
                                        )
                                    })}
                            </Card>
                        </Col>
                    )}
                </Row>
                <Modal open={this.state.error} close={() => this.setState({ error: false })}>
                    <h3>Error</h3>
                    <p>Changes were not saved</p>
                    {JSON.stringify(this.state.error)}
                </Modal>
            </Fragment>
        )
    }
}

export default EntityComponentContainer(QuestionEntity, {
    store: 'services',
    name: 'questions',
    saveFunc: saveQuestion,
    deleteFunc: deleteQuestion,
})
