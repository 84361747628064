import React from 'react'
import { Button, Divider, Icon, Ellipsis } from '@advanza/ui'
import TableList from 'components/TableList'
import LoadBoxWrapper from 'components/ui/LoadBoxWrapper'
import { useKeywordsList, useAddKeyword } from 'hooks/keywordHooks'
import Pagination from 'components/Pagination'
import { useOptions } from 'hooks/optionHooks'
import KeywordEntity from './KeywordEntity'
import ExpandableEntity from 'components/FlexTable/ExpandableEntity'
import { Col, Row } from '@advanza/grid'
import A from 'components/ui/A'

const definitions = {
    services_keyword_id: { name: '#' },
    keyword: { name: 'keyword' },
    option_id: { name: 'filter' },
    active: { name: 'status' },
    category: { name: 'category' },
}

const renderRow = (id, entities, _options, questions, serviceId) => {
    const { keywords } = entities
    const entity = keywords[id]

    return {
        cols: Object.keys(definitions).map((key) => {
            switch (key) {
                case 'services_keyword_id':
                    return typeof entity[key] === 'string' ? <Icon name="fiber_new" /> : entity[key]
                case 'active':
                    return entity[key] === true ? 'active' : 'inactive'
                case 'category':
                    return entity[key] === true ? <b>category</b> : ''
                case 'option_id':
                    return _options[entity[key]]
                        ? questions[_options[entity[key]].question_id].question +
                              ' | ' +
                              _options[entity[key]].value
                        : ''
                default:
                    return entity[key]
            }
        }),
        id,
        isLoading: entity._saving,
        expandable: (
            <ExpandableEntity>
                <KeywordEntity id={id} serviceId={serviceId} />
            </ExpandableEntity>
        ),
        expanded: entity._open,
        expandOnClick: true,
    }
}

const KeywordsList = ({ filterId, pageSize = 10, options = {}, serviceId }) => {
    const {
        filter = {},
        currentPage,
        selectable = true,
        changeFilter,
        pageNr,
        ...rest
    } = useKeywordsList(filterId, { ...options, pageSize })
    const addKeyword = useAddKeyword(filterId, options)

    const { options: _options = {}, questions = {} } = useOptions(serviceId)

    const rows = {}
    currentPage.forEach((id) => {
        rows[id] = renderRow(id, rest.entities, _options, questions, serviceId)
    })

    return (
        <LoadBoxWrapper isLoading={rest.isFetching}>
            <Row>
                {rest.count && (
                    <Col x>
                        <Pagination
                            current={pageNr}
                            pageSize={parseInt(filter.pageSize)}
                            count={parseInt(rest.count)}
                            onPageChange={(page) => changeFilter({ page })}
                        />
                    </Col>
                )}
                <Col x>
                    <A
                        target="_blank"
                        external
                        href={`/api/office/services-keywords/download-keywords/${serviceId}`}>
                        Download as JSON <Icon name="get_app" />
                    </A>
                </Col>
            </Row>

            <TableList {...rest} filter={filter} rows={rows} definitions={definitions} />
            <Divider m />
            <Row end="xs">
                <Col x>
                    <Button name="text" onClick={addKeyword}>
                        <Icon name="add" className="colorCta" style={{ marginTop: '-4px' }} /> Add
                        Keyword
                    </Button>
                </Col>
            </Row>
        </LoadBoxWrapper>
    )
}

export default KeywordsList
