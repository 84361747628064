import { Col, Row } from '@advanza/grid'
import { InputField, SelectCheckbox, SelectSimple } from '@advanza/input'
import { Divider, Icon, PreIcon } from '@advanza/ui'
import {
    Awin,
    Bing,
    BingPaid,
    Facebook,
    GooglePaid,
    GoogleSearch,
    Instagram,
    Roomburg,
} from 'components/requests/requestSourceIcons'
import Restricted from 'components/Restricted'
import { localToUTC, SQL_DATE_TIME_FORMAT_FNS, utcToLocal } from 'date'
import queryString from 'query-string'
import React, { Fragment } from 'react'
import RequestFilterContainer from '../../containers/RequestFilterContainer'
import DatePicker from '../DatePicker'
import Pagination from '../Pagination'
import RequestStatusSelect from '../requests/RequestStatusSelect'
import ServicesSelect from '../services/ServicesSelect'
import style from './RequestFilter.module.css'

const PreIconLike = ({ icon, children }) => (
    <Row middle="xs" style={{ flexWrap: 'nowrap' }}>
        <Col x style={{ width: 40, display: 'flex', justifyContent: 'center' }}>
            {icon}
        </Col>
        {children}
    </Row>
)

class RequestFilter extends React.Component {
    constructor(props) {
        super(props)
        this.onCountList = this.onCountList.bind(this)
        this.onPageChange = this.onPageChange.bind(this)
        this.onQueryChange = this.onQueryChange.bind(this)
        this.updateQuery = this.updateQuery.bind(this)
        this.onServiceIdsChange = this.onServiceIdsChange.bind(this)
        this.onRequestStatusChange = this.onRequestStatusChange.bind(this)
        this.invalidate = this.invalidate.bind(this)
        this.onOrderChange = this.onOrderChange.bind(this)
        this.onFlagsChange = this.onFlagsChange.bind(this)
        this.onPageSizeChange = this.onPageSizeChange.bind(this)
        this.onDateChange = this.onDateChange.bind(this)

        this.state = {
            query: '',
        }
    }

    getQuery() {
        const parsed = queryString.parse(window.location.search.replace('?', ''), {
            arrayFormat: 'bracket',
        })
        parsed.page = parseInt(parsed.page) || null
        return parsed
    }

    onSetQuery = (diff = {}, replace = false) => {
        const url = new URL(window.location)
        const newOptions = { ...(replace ? {} : this.getQuery()), ...diff }
        newOptions.page = parseInt(newOptions.page) || null
        url.search = queryString.stringify(newOptions, { arrayFormat: 'bracket' })
        window.history.pushState({}, '', url.toString())
    }

    _changeFilter(filterOptions, replace = false) {
        const { filterId, changeFilter } = this.props
        this.onSetQuery(filterOptions, replace)
        changeFilter(filterId, filterOptions, { replace })
    }

    componentDidMount() {
        const { defaultOptions = {}, countries, filter, filterSource } = this.props
        this._changeFilter({
            ...this.getQuery(),
            ...defaultOptions,
            source: filterSource,
            countries,
        })
        if (!filter) {
        } else if (filter.source !== filterSource) {
            this._changeFilter({
                ...filter,
                source: filterSource,
                pageSize: defaultOptions.pageSize,
                didInvalidate: true,
                page: 0,
                countries,
            })
        }
    }

    componentDidUpdate(prevProps) {
        const prevFilter = prevProps.filter || {}
        const { filter = {} } = this.props
        const didInvalidate = !prevFilter.didInvalidate && filter.didInvalidate
        if (prevProps.countries !== this.props.countries) {
            this._changeFilter({ countries: this.props.countries })
        }
        if (didInvalidate) {
            // Because the filter is invalidated changing it will refetch the items.
            this._changeFilter({})
        }
    }

    onCountList() {
        this._changeFilter({ countList: true, didInvalidate: true })
    }

    onPageChange(page, invalidate = false) {
        this._changeFilter({ page, didInvalidate: invalidate })
    }

    updateQuery() {
        this._changeFilter({
            query: this.state.query,
            page: 0,
        })
    }

    onServiceIdsChange(values) {
        this._changeFilter({
            serviceIds: values,
            page: 0,
        })
    }

    onRequestStatusChange(values) {
        this._changeFilter({
            statusArray: values,
            page: 0,
        })
    }

    invalidate() {
        this._changeFilter({
            didInvalidate: true,
            page: 0,
        })
    }

    onQueryChange(e) {
        this.setState({ query: e.target.value }, this.updateQuery)
    }

    onOrderChange(values) {
        this._changeFilter({ order: values.map(({ value }) => value) })
    }

    onFlagsChange(values) {
        this._changeFilter({ flags: values, page: 0 })
    }

    onPageSizeChange(e) {
        const { value } = e.target
        this._changeFilter({ pageSize: value, page: 0 })
    }

    onDateChange(e) {
        const { name, value } = e.target
        if (!value) {
            this._changeFilter({ [name]: null, page: 0 })
        } else {
            const dateTimeLocalStr =
                value.split(' ')[0] + ' ' + (name === 'startDate' ? '00:00:00' : '23:59:59')
            this._changeFilter({
                [name]: localToUTC(dateTimeLocalStr, SQL_DATE_TIME_FORMAT_FNS),
                page: 0,
            })
        }
    }

    render() {
        const { filter, count, countOnPage, compact } = this.props
        const { query } = this.state
        if (!filter || compact) {
            return null
        }
        const pageNr = filter.page || 0
        const { protocol, hostname } = window.location
        return (
            <Fragment>
                <Row middle="xs">
                    <Col x>
                        <h1>Requests</h1>
                    </Col>
                    <Col x style={{ marginLeft: 'auto' }}>
                        <Restricted location="export" checkWrite={false}>
                            <PreIcon name="exit_to_app">
                                <a
                                    target="_blank"
                                    href={`${protocol}//${hostname}/api/office/requests/export/?${queryString.stringify(
                                        { ...filter, page: 1 },
                                        { arrayFormat: 'bracket' }
                                    )}`}>
                                    Export
                                </a>
                            </PreIcon>
                        </Restricted>
                    </Col>
                </Row>
                <Divider m />
                <div className={style.grid}>
                    <div>
                        <ServicesSelect
                            large
                            onChangeValue={this.onServiceIdsChange}
                            value={filter.serviceIds || []}
                        />
                    </div>
                    <div>
                        <SelectCheckbox
                            large
                            options={[
                                { value: 'valid', name: 'Valid requests' },
                                { value: 'all', name: 'All Requests' },
                                { value: 'zeros', name: 'Without matches' },
                                { value: 'invalid', name: 'Invalid Requests' },
                                { value: 'demandShouldConfirm', name: 'Demand should confirm' },
                                { value: 'reclaims', name: 'Reclaims' },
                                { value: 'affiliate', name: 'Affiliate requests' },
                                { value: 'deleted', name: 'Deleted requests' },
                                { value: 'partial_match', name: 'Partial matches' },
                                { value: 'hasReclaim', name: 'hasReclaim' },
                                { value: 'eagerForReplies', name: 'No reply received yet' },
                                { value: 'withCandidateMatches', name: 'With candidate matches' },
                            ]}
                            onChangeValue={this.onFlagsChange}
                            value={filter.flags || []}
                            placeholder="all requests"
                        />
                    </div>
                    <div>
                        <RequestStatusSelect
                            large
                            onChangeValue={this.onRequestStatusChange}
                            value={filter.statusArray || []}
                        />
                    </div>
                    <div>
                        <InputField
                            icon="search"
                            placeholder="search"
                            value={query || filter.query}
                            onChange={this.onQueryChange}
                            onKeyPress={(e) => e.key === 'Enter' && this.updateQuery()}
                        />
                    </div>
                    <div>
                        <DatePicker
                            onChange={this.onDateChange}
                            dayPickerProps={{
                                disabled: (date) => date > new Date(),
                                showOutsideDays: false,
                            }}
                            value={utcToLocal(filter.startDate, SQL_DATE_TIME_FORMAT_FNS)}
                            name="startDate"
                        />
                    </div>
                    <div>
                        <DatePicker
                            onChange={this.onDateChange}
                            dayPickerProps={{ disabled: (date) => date > new Date() }}
                            value={utcToLocal(filter.endDate, SQL_DATE_TIME_FORMAT_FNS)}
                            name="endDate"
                        />
                    </div>
                    <div>
                        <button onClick={this.invalidate}>
                            <Icon name="refresh" />
                        </button>
                    </div>
                    <div>
                        <Pagination
                            current={pageNr}
                            pageSize={filter.pageSize}
                            count={count}
                            countOnPage={countOnPage}
                            onCountList={this.onCountList}
                            onPageChange={this.onPageChange}
                        />
                    </div>
                    <div>
                        <SelectCheckbox
                            value={filter.sources}
                            onChangeValue={(sources) => this._changeFilter({ sources })}
                            placeholder="source"
                            options={[
                                {
                                    value: 'googlePaid',
                                    name: (
                                        <PreIconLike icon={<GooglePaid />}>Google Ads</PreIconLike>
                                    ),
                                },
                                {
                                    value: 'googleOrganic',
                                    name: (
                                        <PreIconLike icon={<GoogleSearch />}>
                                            Google Search
                                        </PreIconLike>
                                    ),
                                },
                                {
                                    value: 'bingPaid',
                                    name: <PreIconLike icon={<BingPaid />}>Bing Ads</PreIconLike>,
                                },
                                {
                                    value: 'bingOrganic',
                                    name: <PreIconLike icon={<Bing />}>Bing Search</PreIconLike>,
                                },
                                {
                                    value: 'awin',
                                    name: <PreIconLike icon={<Awin />}>Awin</PreIconLike>,
                                },
                                {
                                    value: 'ig.com',
                                    name: <PreIconLike icon={<Instagram />}>Instagram</PreIconLike>,
                                },
                                {
                                    value: 'fb.com',
                                    name: (
                                        <PreIconLike icon={<Facebook />}>Facebook.com</PreIconLike>
                                    ),
                                },
                                {
                                    value: 'cross_sell',
                                    name: (
                                        <PreIconLike icon={<Icon name="add_shopping_cart" />}>
                                            Cross sell
                                        </PreIconLike>
                                    ),
                                },
                                {
                                    value: 'snel-gratis-offertes',
                                    name: (
                                        <PreIconLike
                                            icon={
                                                <img
                                                    src={`https://www.google.com/s2/favicons?domain=snelgratisoffertes.nl&sz=24`}
                                                />
                                            }>
                                            Snel gratis offertes
                                        </PreIconLike>
                                    ),
                                },
                                {
                                    value: 'verbouwkosten',
                                    name: (
                                        <PreIconLike
                                            icon={
                                                <img
                                                    src={`https://www.google.com/s2/favicons?domain=verbouwkosten.com&sz=24`}
                                                />
                                            }>
                                            Verbouwkosten
                                        </PreIconLike>
                                    ),
                                },
                                {
                                    value: 'performanceMax',
                                    name: (
                                        <PreIconLike icon={<Icon name="insights" />}>
                                            Performance Max
                                        </PreIconLike>
                                    ),
                                },
                                {
                                    value: 'roomburg',
                                    name: <PreIconLike icon={<Roomburg />}>Roomburg</PreIconLike>,
                                },
                                {
                                    value: 'other',
                                    name: (
                                        <PreIconLike icon={<Icon name="not_listed_location" />} />
                                    ),
                                },
                            ]}
                        />
                    </div>
                    <div>
                        <SelectSimple
                            value={filter.pageSize}
                            onChange={(e) =>
                                this._changeFilter({
                                    pageSize: e.target.value,
                                    page: 0,
                                    pageSize_: e.target.value,
                                })
                            }
                            placeholder="page size"
                            options={[
                                { name: '20', value: 20 },
                                { name: '50', value: 50 },
                                { name: '100', value: 100 },
                                { name: '200', value: 200 },
                            ]}
                        />
                    </div>
                    <div>
                        <SelectSimple
                            value={filter.requestType}
                            onChange={(e) =>
                                this._changeFilter({
                                    requestType: e.target.value,
                                    page: 0,
                                    pageSize_: e.target.value,
                                })
                            }
                            placeholder="Request Type"
                            options={[
                                { name: 'quote', value: 'quote' },
                                { name: 'contact', value: 'contact' },
                                { name: 'call', value: 'call' },
                                { name: 'availability', value: 'availability' },
                                { name: 'brochure', value: 'brochure' },
                            ]}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default RequestFilterContainer(RequestFilter)
